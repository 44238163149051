import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Example icons: update these imports if needed
import arrowForward2 from "../../Images/arrow_forward-2.svg";
import ChatHistory from "./ChatHistory.jsx";

// Your custom CSS
import "../AI_Copilot/PromptChat.css";

const PromptChat = ({
  documentKey = "",
  documentUrl = "",
  documentType = "",
  prompt = "",
  finalResponse = "",
  generatedQuestions = [],
  chatId,
  isMenuOpen,
  /** The text from parent you want automatically sent as a user message on mount **/
  chatInput,
  onNewChat,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const sentRef = useRef(false);

  // =============== STATE ===============

  // -- (A) Restore chatHistory from localStorage, or start with []
  const [chatHistory, setChatHistory] = useState(() => {
    try {
      const stored = localStorage.getItem("promptChat_history");
      return stored ? JSON.parse(stored) : [];
    } catch {
      return [];
    }
  });

  // Persist chatHistory to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("promptChat_history", JSON.stringify(chatHistory));
  }, [chatHistory]);

  // -- (B) Restore chatResponse (finalResponse) from localStorage, or empty
  const [chatResponse, setChatResponse] = useState(() => {
    try {
      return localStorage.getItem("promptChat_finalResponse") || "";
    } catch {
      return "";
    }
  });
  // Update localStorage whenever chatResponse changes
  useEffect(() => {
    localStorage.setItem("promptChat_finalResponse", chatResponse);
  }, [chatResponse]);

  // Update chatResponse whenever parent’s finalResponse changes
  useEffect(() => {
    if (finalResponse) {
      setChatResponse(finalResponse);
    }
  }, [finalResponse]);

  // -- (C) Restore generatedPromptQuestions from localStorage
  const [generatedPromptQuestions, setGeneratedPromptQuestions] = useState(() => {
    try {
      const stored = localStorage.getItem("promptChat_questions");
      return stored ? JSON.parse(stored) : [];
    } catch {
      return [];
    }
  });
  // Persist to localStorage
  useEffect(() => {
    localStorage.setItem(
      "promptChat_questions",
      JSON.stringify(generatedPromptQuestions)
    );
  }, [generatedPromptQuestions]);

  // -- (D) Restore clientMessage from localStorage
  const [clientMessage, setClientMessage] = useState(() => {
    try {
      return localStorage.getItem("promptChat_clientMessage") || "";
    } catch {
      return "";
    }
  });
  // Persist to localStorage
  useEffect(() => {
    localStorage.setItem("promptChat_clientMessage", clientMessage);
  }, [clientMessage]);

  // Additional states if you need them
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  // Redux or other selectors
  const error = useSelector((state) => state.pdfChat?.error);
  const { runId } = useSelector((state) => state.pdfChat);

  // If parent used react-router state to pass data
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl, docType } = location.state || {};

  // internal key for doc
  const [pdfKey, setPdfKey] = useState(dynamicPdfKey || "");
  const [pdfUrl, setPdfUrl] = useState("");

  const [clickedIndex, setClickedIndex] = useState(null);

  // If you want a "local" chatId instead of the parent's
  // (E) Restore from localStorage or use parent's
  const [localChatId, setLocalChatId] = useState(() => {
    try {
      return localStorage.getItem("promptChat_chatId") || chatId || null;
    } catch {
      return chatId || null;
    }
  });

  // Whenever localChatId changes, persist it
  useEffect(() => {
    if (localChatId) {
      localStorage.setItem("promptChat_chatId", localChatId);
    } else {
      localStorage.removeItem("promptChat_chatId");
    }
  }, [localChatId]);

  // =============== EFFECTS ===============

  // If we have a dynamic PDF URL
  useEffect(() => {
    if (dynamicPdfUrl) {
      setPdfUrl(dynamicPdfUrl);
    }
  }, [dynamicPdfUrl]);

  // Auto-scroll to bottom on chatHistory update
  // useEffect(() => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTo({
  //       top: chatContainerRef.current.scrollHeight,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [chatHistory]);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // If user is within 50px of the bottom, keep auto-scroll on; otherwise disable it
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 50;
    setAutoScrollEnabled(isNearBottom);
  };

  useEffect(() => {
    if (!chatContainerRef.current) return;
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (autoScrollEnabled && chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory, autoScrollEnabled]);



  // If parent passes new generatedQuestions, store them
  const streamDummyMessages = useCallback((questions) => {
    const maxToShow = 2;
    const limited = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(limited.length).fill(""));

    limited.forEach((question, idx) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updated = [...prev];
          updated[idx] = question.slice(0, charIndex + 1);
          return updated;
        });
        charIndex++;
        if (charIndex >= question.length) clearInterval(interval);
      }, 30);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(generatedQuestions) && generatedQuestions.length > 0) {
      streamDummyMessages(generatedQuestions);
      // Also store them in state so they remain after reload
      setGeneratedPromptQuestions(generatedQuestions);
    }
  }, [generatedQuestions, streamDummyMessages]);

  useEffect(() => {
    if (generatedPromptQuestions.length > 0) {
      streamDummyMessages(generatedPromptQuestions);
    }
  }, [generatedPromptQuestions, streamDummyMessages]);

  // OPTIONAL: If you want to automatically send parent's chatInput once on mount
  useEffect(() => {
    if (chatInput?.trim() && !sentRef.current) {
      sentRef.current = true;
      handleSendPrompt(chatInput.trim());
    }
  }, [chatInput]);

  // Reset clickedIndex when new questions are generated
  useEffect(() => {
    setClickedIndex(null);
  }, [generatedPromptQuestions]);

  // =============== NEW CHAT (OPTIONAL) ===============
  const [newChatId, setNewChatId] = useState(null);

  const initiateNewChat = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.chat_id) {
        setNewChatId(response.data.chat_id);
        toast.success("New Chat Started");
        setLocalChatId(response.data.chat_id);
      } else {
        throw new Error("Chat ID not received");
      }
    } catch (error) {
      console.error("Error initiating chat:", error);
      toast.error("Failed to start a new chat. Please try again.");
    }
  };

  // =============== MAIN SEND PROMPT (WS) ===============
  const handleSendPrompt = (promptValue) => {
    // 1) Add user message
    const userMsgId = Date.now();
    const userMessage = {
      id: userMsgId,
      sender: "user",
      text: promptValue,
      final: true,
    };
    setChatHistory((prev) => [...prev, userMessage]);
  
    // 2) Placeholder bot message
    const botMsgId = userMsgId + 1;
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false,
    };
    setChatHistory((prev) => [...prev, botPlaceholder]);
  
    // For tracking if we got a 'final' message
    let finalReceived = false;
  
    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]);
    setAutoScrollEnabled(true);
  
    // Decide WS URL & payload
    let wsUrl;
    let payload;
  
    if (documentKey) {
      //  prompt-library-answer endpoint
      wsUrl = `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/prompt-library-answer/${localChatId}/?token=${localStorage.getItem("token")}`;
      payload = { document_key: documentKey, prompt: promptValue };
    } else {
      //  qdrant-copilot-chat
      wsUrl = `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/qdrant-copilot-chat/${localChatId}/?token=${localStorage.getItem("token")}`;
      payload = { client_message: promptValue };
    }
  
    try {
      const ws = new WebSocket(wsUrl);
  
      ws.onopen = () => {
        ws.send(JSON.stringify(payload));
      };
  
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
  
          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) => {
                if (msg.id === botMsgId && !msg.final) {
                  return { ...msg, text: msg.text + data.chunk };
                }
                return msg;
              })
            );
          }
  
          if (data.type === "final") {
            finalReceived = true;
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) => {
                if (msg.id === botMsgId && !msg.final) {
                  return { ...msg, text: msg.text + finalChunk, final: true };
                }
                return msg;
              })
            );
            setIsLoadingResponse(false);
  
            // If we have generated questions
            if (data.data && data.data.generated_questions) {
              const questionsArray = data.data.generated_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }
            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };
  
      // If WS errors out
      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);
  
        // If we never received the final chunk, show fallback bot message
        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I'm having trouble contacting the server right now. Please try again later.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };
  
      // If WS closes before sending final
      ws.onclose = () => {
        // console.log("WebSocket closed");
        setIsLoadingResponse(false);
  
        if (!finalReceived) {
          // Provide fallback message
          setChatHistory((prev) =>
            prev.map((msg) => {
              if (msg.id === botMsgId && !msg.final) {
                return {
                  ...msg,
                  text:
                    "I'm sorry, I couldn't complete your request. The connection was closed or timed out.",
                  final: true,
                };
              }
              return msg;
            })
          );
        }
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);
  
      // Also insert fallback bot message if the WS constructor itself fails
      setChatHistory((prev) =>
        prev.map((msg) => {
          if (msg.id === botMsgId && !msg.final) {
            return {
              ...msg,
              text:
                "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
              final: true,
            };
          }
          return msg;
        })
      );
    }
  };
  


  // =============== NORMAL CHAT (WS) ===============
  const handleUserQuery = (userQuery) => {
    let finalReceived = false; // Track if we got a final response

    // 1) Add the user's message to the chat history
    const userMsgId = Date.now();
    const userMessage = {
      id: userMsgId,
      sender: "user",
      text: userQuery,
      final: true,
    };
    setChatHistory((prev) => [...prev, userMessage]);

    // 2) Create a placeholder for the bot's streaming response
    const botMsgId = userMsgId + 1;
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false,
    };
    setChatHistory((prev) => [...prev, botPlaceholder]);

    // 3) Open a new WebSocket for normal chatting
    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]); // Clear previous suggestions
    setAutoScrollEnabled(true);

    try {
      const ws = new WebSocket(
        `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/qdrant-copilot-chat/${localChatId}/?token=${localStorage.getItem("token")}`
      );

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            client_message: userQuery,
            chat_id: localChatId,
          })
        );
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          // If we receive a 'progress' type chunk: stream partial response
          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + data.chunk }
                  : msg
              )
            );
          }

          // If we receive a 'final' type chunk: mark the message final
          if (data.type === "final") {
            finalReceived = true; // Mark as received final response
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) =>
                msg.id === botMsgId && !msg.final
                  ? { ...msg, text: msg.text + finalChunk, final: true }
                  : msg
              )
            );
            setIsLoadingResponse(false);

            // Handle generated questions
            if (data.data && data.data.generated_questions) {
              const questionsArray = data.data.generated_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }
            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };

      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);

        // If no final response arrived, inject a fallback bot response
        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) =>
              msg.id === botMsgId && !msg.final
                ? {
                  ...msg,
                  text:
                    "I'm sorry, I'm having trouble contacting the server right now. Please try again later.",
                  final: true,
                }
                : msg
            )
          );
        }
      };

      ws.onclose = () => {
        setIsLoadingResponse(false);

        // If the WebSocket closed before a final response was received
        if (!finalReceived) {
          setChatHistory((prev) =>
            prev.map((msg) =>
              msg.id === botMsgId && !msg.final
                ? {
                  ...msg,
                  text:
                    "I'm sorry, I couldn't complete your request. The connection was closed or timed out.",
                  final: true,
                }
                : msg
            )
          );
        }
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);

      // If WebSocket initialization fails, inject a fallback bot response
      setChatHistory((prev) =>
        prev.map((msg) =>
          msg.id === botMsgId && !msg.final
            ? {
              ...msg,
              text:
                "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
              final: true,
            }
            : msg
        )
      );
    }
  };



  // =============== SEND TEXTAREA MESSAGE ===============
  const handleSend = () => {
    if (!clientMessage.trim()) {
      toast.error("Please enter a prompt!");
      return;
    }
    handleUserQuery(clientMessage.trim());
    setClientMessage("");
    setTypedDummyMessages([]); // Clear recommended
  };

  // =============== FORMAT TEXT (OPTIONAL) ===============
  const formatText = useCallback((input) => {
    if (typeof input !== "string" || !input.trim()) return "";
    return input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
  }, []);

  // =============== VIEW DETAILS / CHAT WITH DOC ===============
  const handleViewDetails = (documentKey, documentType) => {
    if (!documentKey) {
      toast.error("File key is not available.");
      return;
    }
    if (documentType === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey: documentKey },
      });
    } else if (documentType === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: documentKey },
      });
    } else {
      console.warn("Unsupported document type:", documentType);
    }
  };

  const handleChatWithDoc = () => {
    if (!documentKey || !documentUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }
    if (documentType === "contract") {
      // Ensure that contractKey is defined and correct
      navigate(`/Contract/contractChat?ckey=${documentKey}`);
    } else if (documentType === "invoice") {
      navigate(
        `/Invoices/Invoice_chat?invoiceKey=${documentKey}&invoiceUrl=${encodeURIComponent(
          documentUrl
        )}`
      );
    } else {
      navigate("/chat", { state: { pdfUrl: documentUrl, pdfKey: documentKey } });
    }
  };

  // =============== THUMBS UP/DOWN & FEEDBACK ===============
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: !prev[messageId] }));
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: false }));
    if (!thumbsUpClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback();
    }
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: !prev[messageId] }));
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: false }));
    if (!thumbsDownClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const submitFeedback = async () => {
    try {
      if (!messageIdToFeedback || !runIdToFeedback || !selectedFeedbackType) {
        // console.error("Missing data for feedback submission.");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: messageIdToFeedback,
          run_id: runIdToFeedback,
          feedback_type: selectedFeedbackType,
          user_feedback: feedbackMessage || "",
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.msg === "Feedback recorded successfully.") {
        // console.log("Feedback submitted successfully!");
      } else {
        // console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");
    setFeedbackMessage(combinedFeedback);

    try {
      await submitFeedback();
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  // =============== CHAT HISTORY SIDEBAR (OPTIONAL) ===============
  const [isOpen, setIsOpen] = useState(false);
  const toggleChatHistory = () => {
    setIsOpen(!isOpen);
  };

  // handling see-more icon (dropdown)
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (messageId) => {
    setOpenDropdownId((prev) => (prev === messageId ? null : messageId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // =============== RENDER ===============
  return (
    <div className="AI-CP-PL-full-container">
      <div className="AI-CP-PL-docChatContainer"
        style={{
          width: isMenuOpen ? "1200px" : "1294px",
        }}>
        {/* Left Section: Chat History (optional) */}
        <div
          className="AI-CP-CopilotChatHistoryContainer flex gap-4"
          style={{
            left: isMenuOpen ? "280px" : "150px",
            position: "absolute",
            top: "86px",
          }}
        >
          {/* onNewChat triggers a brand new chat - clear local storage if you want */}
          <div className="relative group">
            <button
              className="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] shadow flex items-center justify-center"
              onClick={() => {
                // Clear local storage so we start fresh
                localStorage.removeItem("promptChat_history");
                localStorage.removeItem("promptChat_questions");
                localStorage.removeItem("promptChat_clientMessage");
                localStorage.removeItem("promptChat_finalResponse");
                localStorage.removeItem("promptChat_chatId");

                setChatHistory([]);
                setGeneratedPromptQuestions([]);
                setClientMessage("");
                setChatResponse("");
                setLocalChatId(null);

                // Then call parent’s onNewChat logic
                if (onNewChat) onNewChat();
              }}
            >
              <img src="/newChat-icon.svg" alt="New Chat Icon" className="w-4 h-4" />
            </button>

            {/* Tooltip Below */}
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-black text-white text-xs rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            Start New Chat
              {/* Upward Arrow */}
              <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
            </div>
          </div>


          {/* <button className="chat-historyBtn" onClick={toggleChatHistory}>
            <span>Chat History</span>
            <img src={arrowForward2} alt="Forward arrow" />
          </button> */}

          {isOpen && (
            <div
              className="chat-historyDiv"
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                marginTop: "8px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                padding: "10px",
                zIndex: "1000",
              }}
            >
              <p>Your chat history goes here!</p>
              {/* Map or store actual older sessions if needed */}
            </div>
          )}
        </div>

        {/* Main Chat Content */}
        <div className="AI-CP-PL-docChatContent">
          <div className="AI-CP-PL-message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => {
              const isBot = message.sender === "bot";
              const isFinal = message.final;
              const isFirstBotMessage =
                isBot && chatHistory.findIndex((msg) => msg.sender === "bot") === index;

              return (
                <div
                  key={message.id || index}
                  className={`mb-2 ${isBot
                    ? "Ai-CP-bot-message-container w-full flex items-start"
                    : "w-full flex justify-end"
                    }`}
                >
                  {/* BOT ICON */}
                  {isBot && (
                    <div className="PL-bot-icon-container flex-shrink-0 mr-2">
                      <img
                        src="/bot-icon.svg"
                        alt="Bot Icon"
                        className={` ${isFirstBotMessage && isLoadingResponse ? "PL-bot-icon-first h-6 w-6 rounded-full" : "PL-bot-icon h-6 w-6 rounded-full"}`}
                      />
                    </div>
                  )}

                  {/* MESSAGE BUBBLE */}
                  <div className="flex flex-col">
                    {message.sender === "user" ? (
                      <span className="user-message-style">{message.text}</span>
                    ) : (
                      <>
                        {message.final === false && message.text === "" ? (
                          <div className="AI-CP-PL-thinking-indicator  flex items-center">
                            <span className="AI-CP-PL-dot"></span>
                            <span className="AI-CP-PL-dot"></span>
                            <span className="AI-CP-PL-dot"></span>
                          </div>
                        ) : (
                          <span
                            className="AI-CP-PL-bot-message-content"
                            dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
                          />
                        )}
                      </>
                    )}

                    {/* Reaction Buttons if it's a final bot message */}
                    {isBot && isFinal && (
                      <div className="AI-CP-reactionContainer">
                        <div className="Ai-CP-reactions flex gap-2 text-gray-500 mt-2">
                          {!isFirstBotMessage && documentKey && (
                            <span
                              className="see-more-reaction-icon cursor-pointer"
                              ref={dropdownRef}
                            >
                              <img
                                src="/more-icon.svg"
                                alt="more icon"
                                onClick={() => toggleDropdown(message.id)}
                              />
                              {openDropdownId === message.id && (
                                <div className="dropdown-menu">
                                  {documentType !== "normal_document" && (
                                    <button
                                      onClick={() => handleViewDetails(documentKey, documentType)}
                                    >
                                      <img src="/eye-icon.svg" alt="eye icon" />
                                      <span>View More</span>
                                    </button>
                                  )}
                                  <button onClick={handleChatWithDoc}>
                                    <img src="chatButton-icon.svg" alt="chat-button" />
                                    <span>Chat</span>
                                  </button>
                                </div>
                              )}
                            </span>
                          )}

                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsUpClick(message.id)}
                          >
                            <img
                              src={
                                thumbsUpClicked[message.id]
                                  ? "/thums-up-filled.svg"
                                  : "/thums-up.svg"
                              }
                              alt="Thumbs Up"
                            />
                          </span>
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsDownClick(message.id)}
                          >
                            <img
                              src={
                                thumbsDownClicked[message.id]
                                  ? "/thums-down-filled.svg"
                                  : "/thums-down.svg"
                              }
                              alt="Thumbs Down"
                            />
                          </span>
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleCopy(message.id, message.text)}
                          >
                            <img
                              src={
                                copiedMessageId === message.id ? "/copy-clicked.svg" : "/copy.svg"
                              }
                              alt="Copy"
                            />
                          </span>
                        </div>

                        {isFirstBotMessage && documentKey && (
                          <div className="AI-CP-reaction-buttons mt-2">
                            {documentType !== "normal_document" && (
                              <button
                                className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px]"
                                onClick={() => handleViewDetails(documentKey, documentType)}
                              >
                                View More
                              </button>
                            )}
                            <button
                              className="w-[99px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[35px] py-[6px]"
                              onClick={handleChatWithDoc}
                            >
                              Chat
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {/* Error if any */}
            {error && (
              <div className="AI-CP-bot-message-container w-full flex items-start mb-4">
                <div className="AI-CP-bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>

          <div className="dummyContent">
            {/* Recommended Questions */}
            <div className="AI-CP-PL-dummy-container">
              {generatedPromptQuestions?.map((question, idx) => (
                <div
                  key={idx}
                  className={`AI-CP-PL-dummy-message-box border rounded-lg  cursor-pointer ${clickedIndex === idx ? "opacity-50" : "opacity-100"
                    }`}
                  onClick={() => {
                    setClientMessage(question.trim());
                    setClickedIndex(idx);
                  }}
                >
                  <span className="icon mr-2">
                    <img src="/dummy-prompt-icon.svg" alt="Prompt Icon" />
                  </span>
                  <p className="text-sm">{question.trim() || "..."}</p>
                </div>
              ))}
            </div>

            {/* Input Area */}
            <div className="AI-CP-PL-input-container-holder">
              <textarea
                placeholder={
                  isLoadingResponse
                    ? "Copilot is responding..."
                    : "How can I help? Type your Question or choose a prompt"
                }
                className="AI-CP-PL-input-field-holder"
                value={clientMessage}
                onChange={(e) => {
                  setClientMessage(e.target.value);
                  if (textareaRef.current) {
                    textareaRef.current.style.height = "auto";
                    textareaRef.current.style.height = `${Math.min(
                      e.target.scrollHeight,
                      88
                    )}px`;
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (!isLoadingResponse && clientMessage.trim()) {
                      handleSend();
                    }
                  }
                }}
                ref={textareaRef}
              />
              <button
                className="AI-CP-PL-send-button-holder"
                onClick={() => {
                  if (!isLoadingResponse && clientMessage.trim()) {
                    handleSend();
                  }
                }}
                disabled={isLoadingResponse}
              >
                <img
                  src={isLoadingResponse ? "/loading-send-button2.svg" : "/send-copilot.svg"}
                  alt="Send"
                  className={isLoadingResponse ? "PL-send-icon-disabled" : "PL-send-icon"}
                  style={{ width: "56px", height: "56px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Thumbs Down Popup */}
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button className="close-button-thumsdown" onClick={handleClosePopup}>
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          factuallyIncorrect: e.target.checked,
                        }))
                      }
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.refused}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          refused: e.target.checked,
                        }))
                      }
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          notFollowingInstructions: e.target.checked,
                        }))
                      }
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${!feedbackMessage.trim() &&
                    !Object.values(selectedCheckboxes).some((x) => x)
                    ? "disabled-button-thumsdown"
                    : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={
                    !feedbackMessage.trim() &&
                    !Object.values(selectedCheckboxes).some((x) => x)
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptChat;