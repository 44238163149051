import React, { useState, useEffect, useRef } from "react";
import "./ChatHistory.css";

const ChatHistory = ({
  isOpen,
  chatHistory,
  setChatHistory, 
  onSelectChat
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [optionsOpenChatId, setOptionsOpenChatId] = useState(null);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  // Token is now retrieved from localStorage
  const [token, setToken] = useState(localStorage.getItem("token"));

  // Refs to detect clicks outside the dropdown or edit input
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the options menu if click occurs outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionsOpenChatId(null);
      }
      // If editing is active and click is outside the input, commit the change
      if (editingChatId && inputRef.current && !inputRef.current.contains(event.target)) {
        console.log("Click outside detected; committing rename");
        commitRename();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [editingChatId, editingTitle]);

  // Do not render if sidebar is not open
  if (!isOpen) return null;

  // 1. Filter chats by search query
  const filteredChats = chatHistory.filter((chat) =>
    chat.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // 2. Group chats by date range
  const groupChatsByDate = (chats) => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const older = [];
    const now = new Date();

    chats.forEach((chat) => {
      const chatDate = new Date(chat.last_message);
      const diffInTime = now - chatDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

      if (diffInDays === 0) {
        today.push(chat);
      } else if (diffInDays === 1) {
        yesterday.push(chat);
      } else if (diffInDays <= 7) {
        last7Days.push(chat);
      } else {
        older.push(chat);
      }
    });

    return { today, yesterday, last7Days, older };
  };

  const groupedChats = groupChatsByDate(filteredChats);

  // 3. Toggle the dropdown menu
  const handleOptionsClick = (e, chatId) => {
    e.stopPropagation();
    setOptionsOpenChatId((prev) => (prev === chatId ? null : chatId));
  };

  // 4. Start editing a chat title (inline)
  const startEditChatTitle = (e, chat) => {
    e.stopPropagation();
    console.log(`Starting edit for chat ${chat.chat_id} with current title: ${chat.title}`);
    setEditingChatId(chat.chat_id);
    setEditingTitle(chat.title);
    // Optionally, close the options menu
    setOptionsOpenChatId(null);
  };

  // 5. Commit the new title (called on Enter or onBlur)
  const commitRename = async () => {
    if (!editingChatId || isSaving) return;
    if (!editingTitle.trim()) {
      console.log("New title is empty; canceling edit");
      setEditingChatId(null);
      return;
    }

    setIsSaving(true);
    const chatId = editingChatId;
    const newTitle = editingTitle.trim();
    // Remove trailing slash if any
    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/api/v1/copilot/rename-session/`;
    console.log(`Attempting to rename chat ${chatId} to: "${newTitle}"`);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          new_title: newTitle,
        }),
      });

      // Log raw response for debugging
      const text = await response.text();
      console.log("Raw response:", text);
      const data = JSON.parse(text);
      console.log("Parsed JSON response:", data);

      if (response.ok) {
        setChatHistory((prevChats) =>
          prevChats.map((chat) =>
            chat.chat_id === chatId ? { ...chat, title: newTitle } : chat
          )
        );
        console.log(`Chat ${chatId} successfully renamed to "${newTitle}"`);
      } else {
        console.error("Rename failed:", data.msg || "Failed to rename chat.");
      }
    } catch (error) {
      console.error("Error during renaming:", error);
    } finally {
      setEditingChatId(null);
      setIsSaving(false);
    }
  };

  // 6. Delete a chat from the history
  const handleDeleteChat = async (e, chatId) => {
    e.stopPropagation();
    console.log(`Attempting to delete chat ${chatId}`);

    const confirmDelete = window.confirm("Are you sure you want to delete this chat?");
    if (!confirmDelete) {
      console.log("Delete canceled by user.");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/api/v1/copilot/delete-session/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ chat_id: chatId }),
      });

      const text = await response.text();
      console.log("Raw delete response:", text);
      const data = JSON.parse(text);
      console.log("Parsed delete response:", data);

      if (response.ok) {
        setChatHistory((prevChats) => prevChats.filter((chat) => chat.chat_id !== chatId));
        console.log(`Chat ${chatId} deleted successfully`);
      } else {
        console.error("Delete failed:", data.detail || "Failed to delete chat.");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  // Render a single chat item (handles inline editing)
  const renderChatItem = (chat) => (
    <li
      key={chat.chat_id}
      onClick={() => onSelectChat(chat.chat_id)}
      className="chat-item"
    >
      {editingChatId === chat.chat_id ? (
        <input
          ref={inputRef}
          className="chat-item-title editing"
          value={editingTitle}
          onChange={(e) => setEditingTitle(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              console.log("Enter pressed; committing rename");
              commitRename();
            } else if (e.key === "Escape") {
              console.log("Escape pressed; canceling edit");
              setEditingChatId(null);
            }
          }}
          // Commit rename on blur (clicking elsewhere)
          onBlur={commitRename}
          autoFocus
        />
      ) : (
        <span className="chat-item-title" title={chat.title}>
          {chat.title}
        </span>
      )}

      <img
        src="/options-icon.svg"
        alt="options"
        className="options-icon"
        onClick={(e) => handleOptionsClick(e, chat.chat_id)}
      />
      {optionsOpenChatId === chat.chat_id && (
        <div className="chat-options-menu" ref={dropdownRef}>
          <button onClick={(e) => startEditChatTitle(e, chat)}>
            <img src="rename.svg" alt="rename icon" />
            <span>Rename</span>
          </button>
          <button onClick={(e) => handleDeleteChat(e, chat.chat_id)}>
            <img src="delete-chat.svg" alt="delete icon" />
            <span>Delete</span>
          </button>
        </div>
      )}
    </li>
  );

  return (
    <div className="chat-historyDiv">
      {/* Header (Search Bar) */}
      <div className="chat-history-header">
        <div className="ChatHistorySearchBox">
          <img src="/search.svg" alt="search icon" />
          <input
            type="text"
            placeholder="Search"
            className="ChatHistorySearchBox-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="chat-history-body ChatHistory-EmptyContent">
          <h1>No chat history yet!</h1>
          <p className="ml-6">Start a conversation by choosing a prompt or typing a question.</p>
          <img src="ChatHistory-bot-icon.svg" alt="bot icon" className="m-4" />
        </div>

      {/*
      // --- MIDDLE SCROLLABLE PART (Chat List) ---
      {chatHistory.length === 0 ? (
        <div className="chat-history-body ChatHistory-EmptyContent">
          <h1>No chat history yet!</h1>
          <p>Start a conversation by choosing a prompt or typing a question.</p>
          <img src="ChatHistory-bot-icon.svg" alt="bot icon" />
        </div>
      ) : filteredChats.length === 0 ? (
        <div className="chat-history-body ChatHistory-NoResults">
          <h1>No results found</h1>
          <img src="ChatHistory-no-results.svg" alt="No results found icon" />
        </div>
      ) : (
        <div className="chat-history-body">
          {groupedChats.today.length > 0 && (
            <>
              <h2 className="day-heading">Today</h2>
              <ul className="chat-list">
                {groupedChats.today.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.yesterday.length > 0 && (
            <>
              <h2 className="day-heading">Yesterday</h2>
              <ul className="chat-list">
                {groupedChats.yesterday.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.last7Days.length > 0 && (
            <>
              <h2 className="day-heading">Previous 7 Days</h2>
              <ul className="chat-list">
                {groupedChats.last7Days.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
          {groupedChats.older.length > 0 && (
            <>
              <h2 className="day-heading">Previous 30 Days</h2>
              <ul className="chat-list">
                {groupedChats.older.map((chat) => renderChatItem(chat))}
              </ul>
            </>
          )}
        </div>
      )}
      // --- END MIDDLE SCROLLABLE PART ---
      */}

      {/* Footer (New Chat Button) */}
      <div className="chat-history-footer">
        <button className="start-newChat-button">
          <img src="/newChat-history-icon.svg" alt="New Chat Icon" />
          <span>New Chat</span>
        </button>
      </div>
    </div>
  );
};

export default ChatHistory;
