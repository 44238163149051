import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchChatStream = createAsyncThunk(
  "chat/fetchChatStream",
  async ({ folderId, query }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    const wsUrl = `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/chat-with-folder/${folderId}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      // Define types/messages to ignore
      const ignoredTypes = ["info", "status"];
      const ignoredMessages = [
        "Streaming has started.",
        "WebSocket connected",
        "Streaming complete.",
      ];

      socket.onopen = () => {
        console.log("WebSocket connection established for chat stream.");
        socket.send(JSON.stringify({ query }));
        dispatch(updatePartialAnswer(""));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        try {
          const data = JSON.parse(event.data);

          // Skip messages based on type
          if (ignoredTypes.includes(data.type)) {
            console.log(`Skipped message with type: ${data.type}`);
            return;
          }

          // Skip specific messages
          if (data.message && ignoredMessages.some((msg) => data.message.includes(msg))) {
            console.log(`Skipped message: ${data.message}`);
            return;
          }

          if (data.message) {
            accumulatedAnswer += data.message;
            console.log("Accumulated Answer:", accumulatedAnswer);
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === "final") {
            console.log("Final WebSocket message data:", data);
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            generatedQuestions = data.data.generated_questions;

            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
            socket.close();
            resolve(accumulatedAnswer);
          }

          if (data.type === "completed") {
            console.log("Streaming completed:", data);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed.");
      };
    });
  }
);




const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, 
    runId: null,
    generatedQuestions: null,
  },
  reducers: {
    resetChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null;
      state.runId = null; 
      state.generatedQuestions = null;
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId, generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatStream.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatStream.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchChatStream.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});

export const {  updatePartialAnswer, updateFinalAnswer, updateBotMessageInfo, resetChatState } =
  chatSlice.actions;

export default chatSlice.reducer;
