// src/Features/CopilotChatSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Utility: Parse questions into an array
const parseGeneratedQuestions = (questions) => {
  if (Array.isArray(questions)) return questions;
  if (typeof questions === "string") {
    return questions.split(";").map((q) => q.trim()).filter(Boolean);
  }
  return [];
};

// Helper: Load persisted messages from localStorage
const getPersistedMessages = () => {
  try {
    const stored = localStorage.getItem("copilotChat_messages");
    // console.log("Stored messages string:", stored);
    const parsed = stored ? JSON.parse(stored) : [];
    // Filter out messages with empty text or the default "no response" text.
    const filtered = parsed.filter((message) => {
      const trimmedText = message.text.trim();
      return trimmedText !== "" && trimmedText !== "No response received from the server.";
    });
    // console.log("Parsed messages from localStorage:", filtered);
    return filtered;
  } catch (error) {
    console.error("Error reading persisted messages:", error);
    return [];
  }
};




// ------------------------------------------
// Thunk: Fetch conversation from PDF (Chat)
// ------------------------------------------
export const fetchCopilotChat = createAsyncThunk(
  "pdfChat/fetchCopilotChat",
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    if (!token) return rejectWithValue("No authorization token found");

    const wsUrl = `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/qdrant-chat/${pdfKey}/?token=${token}`;
    
    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";

      socket.onopen = () => {
        // console.log("WebSocket (fetchCopilotChat) connected:", wsUrl);
        socket.send(JSON.stringify({ client_message: clientMessage }));

        // Indicate loading, clear partial
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
        // Make sure we start with streaming OFF
        dispatch(setIsStreaming(false));
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          // console.log("WebSocket (fetchCopilotChat) message:", data);

          // If partial chunk arrives => streaming is active
          if (data.chunk) {
            dispatch(setIsStreaming(true)); // streaming on
            accumulatedAnswer += data.chunk;
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          // On final, store final answer and generated questions
          if (data.type === "final") {
            const rawQuestions = data.data.generated_questions || [];
            const generatedQuestions = parseGeneratedQuestions(rawQuestions);

            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateGeneratedQuestions(generatedQuestions));

            // Streaming is done
            dispatch(setIsStreaming(false));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error (fetchCopilotChat):", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket (fetchCopilotChat) closed.");
      };
    });
  }
);

// ------------------------------------------
// Thunk: Fetch Document Summary (Chat)
// ------------------------------------------
export const fetchDocumentSummary = createAsyncThunk(
  "chat/fetchDocumentSummary",
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    if (!token) return rejectWithValue("No authorization token found");

       const wsUrl = `wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/pdf-summary-and-chats/${pdfKey}/?token=${token}`;

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";

      socket.onopen = () => {
        // console.log("WebSocket (fetchDocumentSummary) connected:", wsUrl);
        socket.send(JSON.stringify({ client_message: clientMessage }));

        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
        dispatch(setIsStreaming(false)); // Start streaming off
      };

      socket.onmessage = (event) => {
        try {
          const rawData = event.data.startsWith("data:")
            ? event.data.slice(5).trim()
            : event.data;

          const data = JSON.parse(rawData);
          // console.log("WebSocket (fetchDocumentSummary) message:", data);

          if (data.type === "progress" && data.chunk) {
            dispatch(setIsStreaming(true)); // streaming on
            accumulatedAnswer += data.chunk;
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.pdf_details) {
            const rawQuestions = data.pdf_details.example_questions || [];
            const generatedQuestions = parseGeneratedQuestions(rawQuestions);

            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateGeneratedQuestions(generatedQuestions));
            dispatch(updateStatus("succeeded"));

            // End streaming
            dispatch(setIsStreaming(false));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error (fetchDocumentSummary):", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        // console.log("WebSocket (fetchDocumentSummary) closed.");
      };
    });
  }
);

const initialState = {
  messages: getPersistedMessages(),
  partialAnswer: "",
  finalAnswer: "",
  status: "idle",
  error: null,
  generatedQuestions: [],
  botMessageId: null,
  runId: null,
  isStreaming: false,
};

// console.log("Initial state of pdfChat slice:", initialState);

const CopilotChatSlice = createSlice({
  name: "pdfChat",
  initialState,
   
  reducers: {
    resetPdfChatState: (state) => {
      state.messages = [];
      state.partialAnswer = "";
      state.finalAnswer = "";
      state.status = "idle";
      state.error = null;
      state.generatedQuestions = [];
      state.botMessageId = null;
      state.runId = null;
      state.isStreaming = false;
      localStorage.removeItem("copilotChat_messages");
    },
    addUserMessage: (state, action) => {
      // console.log("addUserMessage reducer called with payload:", action.payload);
      const trimmedText = action.payload.trim();
      // Do not add the message if it's empty or matches the unwanted text
      if (trimmedText === "" || trimmedText === "No response received from the server.") {
        return;
      }
      state.messages.push({
        id: Date.now(),
        sender: "user",
        text: action.payload,
        final: true,
      });      
      
      localStorage.setItem("copilotChat_messages", JSON.stringify(state.messages));
      // console.log("Added user message, updated localStorage:", JSON.stringify(state.messages));

    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.sender === "bot" && !lastMessage.final) {
        lastMessage.text = action.payload;
      } else {
        state.messages.push({
          id: Date.now(),
          sender: "bot",
          text: action.payload,
          final: false,
        });
      }
      localStorage.setItem("copilotChat_messages", JSON.stringify(state.messages));
      // console.log("Updated partial answer, localStorage messages:", JSON.stringify(state.messages));
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.sender === "bot" && !lastMessage.final) {
        lastMessage.text = action.payload;
        lastMessage.final = true;
      } else {
        state.messages.push({
          id: Date.now(),
          sender: "bot",
          text: action.payload,
          final: true,
        });
      }
      localStorage.setItem("copilotChat_messages", JSON.stringify(state.messages));
      // console.log("Updated final answer, localStorage messages:", JSON.stringify(state.messages));

    },
    updateGeneratedQuestions: (state, action) => {
      state.generatedQuestions = parseGeneratedQuestions(action.payload);
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    setIsStreaming: (state, action) => {
      state.isStreaming = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchCopilotChat
      .addCase(fetchCopilotChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCopilotChat.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchCopilotChat.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })

      // fetchDocumentSummary
      .addCase(fetchDocumentSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDocumentSummary.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchDocumentSummary.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const {
  resetPdfChatState,
  addUserMessage,
  updatePartialAnswer,
  updateFinalAnswer,
  updateGeneratedQuestions,
  updateStatus,
  setIsStreaming,
} = CopilotChatSlice.actions;

export default CopilotChatSlice.reducer;
