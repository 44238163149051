// src/components/CopilotChat/CopilotChat.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCopilotChat,
  resetPdfChatState,
  fetchDocumentSummary,
  addUserMessage,
} from "../../Features/CopilotChatSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "./CopilotChat.css";
import "../Contract/contractChat.css";
import arrowForward2 from "../../Images/arrow_forward-2.svg";

const CopilotChat = ({ isMenuOpen }) => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Local UI states
  const [clientMessage, setClientMessage] = useState("");
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Chat history toggle
  const dropdownRef = useRef(null);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const [chatInput, setChatInput] = useState("");
  const [chatId, setChatId] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  // From location
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl, docType } = location.state || {};

  // Redux states
  const {
    messages,
    status,
    updatePartialAnswer,
    updateFinalAnswer,
    generatedQuestions,
    error,
    botMessageId,
    runId,
  } = useSelector((state) => state.copilotChat);
  const { isStreaming } = useSelector((state) => state.copilotChat);
  // console.log("messagesssss", messages);

  // Additional local states
  const [token, setToken] = useState(null);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);



  // Near the top of CopilotChat.jsx:
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Then your toggle function:
  const toggleDropdown = (messageId) => {
    setOpenDropdownId((prev) => (prev === messageId ? null : messageId));
  };

  // Retrieve token from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      toast.error("Authentication token is missing. Please log in again.");
    }
  }, []);

  // Fetch Document Summary on Mount (second WebSocket)
  useEffect(() => {
    if (!dynamicPdfKey || !token) return;
    dispatch(fetchDocumentSummary({ pdfKey: dynamicPdfKey, clientMessage: "Summarize the document" }))
      .unwrap()
      .then(() => {
        setIsSummaryLoading(false);
        setIsSummaryCompleted(true);
      })
      .catch((err) => {
        console.error("Error fetching document summary:", err);
        setIsSummaryLoading(false);
        toast.error("Failed to fetch document summary.");
      });
  }, [dynamicPdfKey, token, dispatch]);

  // Keep the PDF URL if provided
  const [contractUrl, setContractUrl] = useState("");
  useEffect(() => {
    if (dynamicPdfUrl) {
      setContractUrl(dynamicPdfUrl);
    }
  }, [dynamicPdfUrl]);

  // Scroll to bottom whenever messages change
  // useEffect(() => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTo({
  //       top: chatContainerRef.current.scrollHeight,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [messages]);

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // If user is within 50px of the bottom, keep autoScroll on; otherwise disable it
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 50;
    setAutoScrollEnabled(isNearBottom);
  };
  useEffect(() => {
    if (!chatContainerRef.current) return;
    const container = chatContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (autoScrollEnabled && chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, autoScrollEnabled]);

  // Cleanup on unmount
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetPdfChatState());
  //   };
  // }, [dispatch]);

  // ------------------------------------------------------
  // Send a new query (first WebSocket)
  // ------------------------------------------------------
  const handleSend = () => {
    if (!clientMessage.trim() || isAwaitingResponse || !token) return;

    // console.log("handleSend called with clientMessage =", clientMessage);


    setAutoScrollEnabled(true); // Re-enable auto-scroll at start of a new response
    // console.log("Dispatching addUserMessage:", clientMessage);

    dispatch(addUserMessage(clientMessage));
    // console.log("...dispatched addUserMessage");



    dispatch(fetchCopilotChat({ pdfKey: dynamicPdfKey, clientMessage }))
      .unwrap()
      .then(() => {
        setIsAwaitingResponse(false);
        // setIsThinking(false);
        setShowDummyMessages(true); // Show updated dummy messages after response
      })
      .catch((err) => {
        console.error("Error fetching PDF chat:", err);
        setIsAwaitingResponse(false);
        // setIsThinking(false);
        toast.error("Failed to get response from the server.");
      });

    setClientMessage("");
    setIsAwaitingResponse(true);
    // setIsThinking(true);
    setShowDummyMessages(false); // Hide dummy messages while response is being processed
  };


  /// stream dummy messages 
  const streamDummyMessages = useCallback((questions) => {
    // console.log("==> streamDummyMessages called with:", questions);

    const maxToShow = 2;
    const limited = questions.slice(0, maxToShow);

    // Reset typedDummyMessages before streaming new ones
    setTypedDummyMessages([]);
    // console.log("==> Resetting typedDummyMessages to []");

    limited.forEach((question, idx) => {
      // console.log(`==> Streaming question[${idx}]:`, question);

      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          // Ensure we have an array of correct length
          const updated = [...(prev || [])];

          // If for some reason it doesn't exist yet, fill up to idx
          while (updated.length < limited.length) {
            updated.push("");
          }

          updated[idx] = question.slice(0, charIndex + 1);
          return updated;
        });
        charIndex++;
        if (charIndex >= question.length) clearInterval(interval);
      }, 30); // typing speed
    });
  }, []);


  useEffect(() => {
    // console.log("==> generatedQuestions changed:", generatedQuestions);
    if (Array.isArray(generatedQuestions) && generatedQuestions.length > 0) {
      setClickedIndex(null); // Reset clicked index for new questions
      streamDummyMessages(generatedQuestions); // Dynamically stream dummy questions
    } else {
      // Clear typedDummyMessages if no new questions
      setTypedDummyMessages([]);
    }
  }, [generatedQuestions, streamDummyMessages]);




  // ------------------------------------------------------
  // Thumbs Up/Down Feedback
  // ------------------------------------------------------
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
    setMessageIdToFeedback(messageId);
    setRunIdToFeedback(runId);
    setSelectedFeedbackType("down");
    setShowThumbsDownPopup(true);
  };

  // ------------------------------------------------------
  // Copy
  // ------------------------------------------------------
  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
        toast.success("Message copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
        toast.error("Failed to copy message.");
      });
  };

  // ------------------------------------------------------
  // Thumbs Down Modal
  // ------------------------------------------------------
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() &&
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) selectedFeedback.push("Not factually correct.");
    if (selectedCheckboxes.refused) selectedFeedback.push("Refused when it shouldn’t have.");
    if (selectedCheckboxes.notFollowingInstructions) selectedFeedback.push("Not Following Instructions.");

    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");

    try {
      await axios.post("/api/feedback", {
        messageId: messageIdToFeedback,
        runId: runIdToFeedback,
        feedback: combinedFeedback,
      });
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
      toast.success("Feedback submitted. Thank you!");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback. Please try again.");
    }
  };

  // format bot message
  // Adjust the .replace() calls to handle exactly the patterns you want.
  const formatText = (text) => {
    if (typeof text !== "string") return "";

    return text
      // Replace newlines with <br/>
      .replace(/\n/g, "<br/>")

      // Replace "**bold**" with <b>bold</b>
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")

      // Example: "### Something" => "<strong>Something</strong>"
      .replace(/###\s?(.*?)/g, "<strong>$1</strong>")

      // Turn "- item" into HTML list items
      .replace(/^-\s(.+)/gm, "<li>$1</li>")

      // Wrap consecutive <li> tags in <ul> ... </ul>
      .replace(/(<li>.*?<\/li>)/gs, "<ul>$1</ul>")

      // Convert "Label: Value" lines to <b>Label</b>: Value
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
  };

  // ------------------------------------------------------
  // Navigation / Utility
  // ------------------------------------------------------
  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  const handleViewDetails = (pdfKey, docType) => {
    if (!pdfKey) {
      alert("File key is not available.");
      return;
    }
    if (docType === "contract") {
      navigate("/Contract/contract-details", { state: { contractKey: pdfKey } });
    } else if (docType === "invoice") {
      navigate("/Invoices/Invoice_Details", { state: { invoiceKey: pdfKey } });
    } else {
      console.warn("Unsupported document type:", docType);
    }
    localStorage.removeItem("copilotChat_messages");

  };

  const handleChatWithDoc = () => {
    if (!dynamicPdfKey || !contractUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }

    if (docType === "contract") {
      // Ensure that contractKey is defined and correctly represents the contract identifier
      navigate(`/Contract/contractChat?ckey=${dynamicPdfKey}`);
    }

    else if (docType === "invoice") {
      navigate(`/Invoices/Invoice_chat?invoiceKey=${dynamicPdfKey}&invoiceUrl=${encodeURIComponent(contractUrl)}`);

    } else {
      navigate("/chat", { state: { pdfUrl: contractUrl, pdfKey: dynamicPdfKey } });

    }
    localStorage.removeItem("copilotChat_messages");

  };

  // Example "New Chat" function
  const handleNewChat = async () => {

    dispatch(resetPdfChatState());
    // console.log("Before removal:", localStorage.getItem("copilotChat_messages"));

    localStorage.setItem("copilotChat_messages", null);
    localStorage.removeItem("copilotChat_messages");

    console.log("After removal:", localStorage.getItem("copilotChat_messages"));


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        { client_message: chatInput },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.removeItem("copilotChat_messages"); // Cleanup again to ensure removal

      const newChatId = response?.data?.chat_id;
      setChatId(newChatId);
      toast.success("New Chat Started");
      // const botMessage = response?.data?.data || "No response received from the server.";
      // dispatch(addUserMessage(botMessage));
      navigate("/ai-copilot");
      window.location.reload();
    } catch (error) {
      console.error("Error while fetching API response:", error);
      dispatch(addUserMessage("Something went wrong. Please try again!"));
      toast.error("Failed to start a new chat.");
    }
  };

  useEffect(() => {
    localStorage.removeItem("copilotChat_messages");
    // Or reset it to an empty array:
    // localStorage.setItem("copilotChat_messages", JSON.stringify([]));
  }, []);


  const handleCopilotButton = () => {
    // setChatId(null);
    // initiateNewChat();
    // setIsPromptChatVisible(false);
    // setChatInput('');
    // setSelectedDoc(null);
    // window.location.reload();

  };

  // Chat history toggle
  const toggleChatHistory = () => setIsOpen(!isOpen);

  // Hide dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setClickedIndex(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // ------------------------------------------------------
  // Render
  // ------------------------------------------------------
  return (
    <div className="AI-CP-full-container">

      <header className="flex justify-between items-center w-full h-[70px] bg-[#fff] border border-black/10 pr-4">


        <button
          className="flex items-center ml-[200px] cursor-pointer bg-transparent border-none" onClick={handleCopilotButton}
        >

          <h2
            className="mr-4"
            style={{
              color: "#000",
              fontFamily: "Outfit, sans-serif",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            AI Copilot
          </h2>
        </button>

      </header>

      {/* Top Buttons: New Chat & Chat History */}
      <div
        className="AI-CP-CopilotChatHistoryContainer flex gap-4"
        style={{
          left: isMenuOpen ? "280px" : "210px",
          position: "absolute",
          top: "80px",
        }}
      >
        <div className="relative group">
          <button
            className="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] shadow flex items-center justify-center"
            onClick={handleNewChat}
          >
            <img src="/newChat-icon.svg" alt="New Chat Icon" className="w-4 h-4" />
          </button>

          {/* Tooltip Below */}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-black text-white text-xs rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            Start New Chat
            {/* Upward Arrow */}
            <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
          </div>
        </div>

        {/* <button className="chat-historyBtn" onClick={toggleChatHistory}>
          <span>Chat History</span>
          <img src={arrowForward2} alt="Forward arrow" />
        </button> */}

        {isOpen && (
          <div
            className="chat-historyDiv"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              marginTop: "8px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              padding: "10px",
              zIndex: "1000",
            }}
          >
            <p>Your chat history goes here!</p>
            {/* Map or store actual chat logs if desired */}
          </div>
        )}
      </div>


      {/* Main Chat Container */}
      <div className="AI-CP-docChatContainer">
        <div className="AI-CP-docChatContent">
          {/* Chat Messages */}
          <div className="AI-CP-message-container mt-4" ref={chatContainerRef}>
            {messages
              .filter(
                (message) => {
                  // If it's a bot message that hasn't finished streaming, always include it.
                  if (message.sender === "bot" && !message.final) {
                    return true;
                  }
                  return message.text.trim() !== "" &&
                    message.text.trim() !== "No response received from the server."
                })
              .map((message, index) => {
                const isBot = message.sender === "bot";
                // Finds the **index** of the first bot message in the entire chat
                const firstBotIndex = messages.findIndex((msg) => msg.sender === "bot");
                const isFirstBotMessage = isBot && index === firstBotIndex;
                const isLatest = index === messages.length - 1;


                return (
                  <div
                    key={message.id}
                    className={`mb-2 ${isBot
                      ? "Ai-CP-bot-message-container  w-full flex items-start"
                      : "w-full flex justify-end"
                      }`}
                  >
                    {isBot && (
                      <div className="bot-icon-container flex-shrink-0">
                        <img
                          src="/bot-icon.svg"
                          alt="Bot Icon"
                          className={
                            // Only apply the "thinking" filter if this is the latest message
                            // and the conditions (e.g. isSummaryLoading or isAwaitingResponse) are met.
                            isLatest && (isSummaryLoading || isAwaitingResponse)
                              ? "AI-CP-bot-icon-thinking h-6 w-6 rounded-full mr-2"
                              : "AI-CP-bot-icon h-6 w-6 rounded-full mr-2"
                          }
                        />
                      </div>
                    )}

                    <div className="flex flex-col -mt-3">
                      {isBot ? (
                        <>
                          {(!message.final && (!message.text || message.text.trim() === "")) ? (
                            // Show thinking dots if streaming hasn't started
                            <div className="AI-CP-thinking-indicator">
                              <span className="AI-CP-dot"></span>
                              <span className="AI-CP-dot"></span>
                              <span className="AI-CP-dot"></span>
                            </div>
                          ) : (
                            // Show bot's partial or final message text
                            <span
                              className="AI-CP-bot-message-content"
                              dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
                            />)}


                          {isBot && message.final && (
                            <div className="AI-CP-reactionContainer">
                              <div className="Ai-CP-reactions flex gap-2 text-gray-500 mt-2">
                                {/* 
         For subsequent messages (NOT first bot message), show "See More" dropdown 
      */}
                                {!isFirstBotMessage && (
                                  <span
                                    className="see-more-reaction-icon cursor-pointer"
                                    ref={dropdownRef}
                                  >
                                    <img
                                      src="/more-icon.svg"
                                      alt="more icon"
                                      onClick={() => toggleDropdown(message.id)}
                                    />
                                    {openDropdownId === message.id && (
                                      <div className="dropdown-menu">
                                        {docType !== "normal_document" && (
                                          <button
                                            onClick={() => handleViewDetails(dynamicPdfKey, docType)}
                                          >
                                            <img src="/eye-icon.svg" alt="eye icon" />
                                            <span>View More</span>
                                          </button>

                                        )}
                                        <button onClick={handleChatWithDoc}>
                                          <img src="/chatButton-icon.svg" alt="chat-button" />
                                          <span>Chat</span>
                                        </button>
                                      </div>
                                    )}
                                  </span>
                                )}

                                {/* Thumbs Up/Down & Copy Icons (show on all bot messages) */}
                                <span
                                  className="reaction-icon cursor-pointer"
                                  onClick={() => handleThumbsUpClick(message.id)}
                                >
                                  <img
                                    src={
                                      thumbsUpClicked[message.id]
                                        ? "/thums-up-filled.svg"
                                        : "/thums-up.svg"
                                    }
                                    alt="Thumbs Up"
                                  />
                                </span>

                                <span
                                  className="reaction-icon cursor-pointer"
                                  onClick={() => handleThumbsDownClick(message.id)}
                                >
                                  <img
                                    src={
                                      thumbsDownClicked[message.id]
                                        ? "/thums-down-filled.svg"
                                        : "/thums-down.svg"
                                    }
                                    alt="Thumbs Down"
                                  />
                                </span>

                                <span
                                  className="reaction-icon cursor-pointer"
                                  onClick={() => handleCopy(message.id, message.text)}
                                >
                                  <img
                                    src={
                                      copiedMessageId === message.id
                                        ? "/copy-clicked.svg"
                                        : "/copy.svg"
                                    }
                                    alt="Copy"
                                  />
                                </span>
                              </div>



                              {isFirstBotMessage && (
                                <div className="AI-CP-reaction-buttons mt-2">
                                  {docType !== "normal_document" && (
                                    <button
                                      className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px]"
                                      onClick={() => handleViewDetails(dynamicPdfKey, docType)}
                                    >
                                      View More
                                    </button>
                                  )}
                                  <button
                                    className="w-[99px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[35px] py-[6px]"
                                    onClick={handleChatWithDoc}
                                  >
                                    Chat
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                        </>
                      ) : (
                        // User message
                        <span className="AI-CP-user-message-bubble mt-2">{message.text}</span>
                      )}
                    </div>
                  </div>
                );
              })}

            {/* Error Display */}
            {error && (
              <div className="AI-CP-bot-message-container w-full flex items-start mb-4">
                <div className="AI-CP-bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>


          <div className="dummyContent">
            {/* Show Generated Questions (optional) */}
            {showDummyMessages && typedDummyMessages.length > 0 && (
              <div className="AI-CP-dummy-container ">
                {typedDummyMessages.map((question, index) => (
                  <div
                    key={index}
                    className={`AI-CP-dummy-message-box border  rounded-lg p-4 flex items-center cursor-pointer ${clickedIndex === index ? "opacity-50" : "opacity-100"
                      }`}
                    onClick={() => {
                      setClientMessage(question.trim());
                      setClickedIndex(index); // Track which prompt was clicked
                    }}
                  >
                    <span className="icon mr-2">
                      <img src="/dummy-prompt-icon.svg" alt="Star Icon" />
                    </span>
                    <p className="text-sm">{question.trim()}</p>
                  </div>
                ))}
              </div>

            )}

            {/* Input & Send Button */}
            <div className="AI-CP-input-container-holder">
              <textarea
                placeholder={
                  isAwaitingResponse || isSummaryLoading
                    ? "Copilot is responding..."
                    : "How can I help? Type your Question or choose a prompt"
                }
                className="AI-CP-input-field-holder"
                value={clientMessage}
                onChange={(e) => {
                  setClientMessage(e.target.value);
                  if (textareaRef.current) {
                    textareaRef.current.style.height = "auto";
                    textareaRef.current.style.height = `${Math.min(
                      e.target.scrollHeight,
                      88
                    )}px`;
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent default Enter behavior

                    // Block sending if streaming is still in progress
                    if (!isStreaming && clientMessage.trim()) {
                      handleSend();
                    }
                  }
                }}

                ref={textareaRef}
                disabled={false} // Ensure textarea is always enabled for typing
              />
              <button
                className="AI-CP-send-button-holder"
                onClick={() => {
                  if (!isAwaitingResponse && !isSummaryLoading && clientMessage.trim()) {
                    handleSend();
                  }
                }}
                disabled={isAwaitingResponse || isSummaryLoading}
              >
                <img
                  src={
                    isSummaryLoading || isAwaitingResponse
                      ? "/loading-send-button2.svg"
                      : "/send-copilot.svg"
                  }
                  alt="Send"
                  className="send-icon"
                  style={{ width: "56px", height: "56px", borderRadius: "0px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Thumbs Down Popup */}
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button className="close-button-thumsdown" onClick={() => setShowThumbsDownPopup(false)}>
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${isSubmitDisabled() ? "disabled-button-thumsdown" : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopilotChat;
