import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import './signup.css'
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { v4 as uuid } from "uuid";
import { FcGoogle } from "react-icons/fc";
import { MdAccountCircle } from "react-icons/md";
import OtpPage from "./OtpSection/OtpPage";
import axios from "axios";
import { useNavigate } from "react-router";
import GoogleSignupButton from "../../components/GoogleSignupButton";
import logo from "../../Images/Logo.png"
import loginImage from '../../Images/loginImage.png'
import errorEmailLogo from '../../Images/emailerror.png'
import { Link } from "react-router-dom";
import GoogleSignInButton from "../../components/GoogleSignInButton";

const SignupPage = () => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
    first_name: "",
    full_name:"", 

  });

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredPassword, setIsHoveredPassword] = useState(false); 


 
  const passwordRegex = {
    minLength: /(?=.{8,})/,
    hasUppercase: /(?=.*[A-Z])/,
    hasNumber: /(?=.*\d)/,
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasNumber: false,
  });

 
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState("");



  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [showTermsError, setShowTermsError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false); 


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

 

 
  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;
    
    
    if (name === "password") {
      const newPassword = value;
      setPasswordValidation({
        minLength: passwordRegex.minLength.test(newPassword),
        hasUppercase: passwordRegex.hasUppercase.test(newPassword),
        hasNumber: passwordRegex.hasNumber.test(newPassword),
      });
      setShowPasswordValidation(false);
    }
  
   
    setSignupDetails((prevSignupDetails) => ({
      ...prevSignupDetails,
      [name]: value,
    }));
  
   
    setEmailError("");
    setIsBtnDisabled(false);
  };
  
  


  
  const [token, setToken] = useState("");

  const handleSignUp = async () => {
    setIsBtnDisabled(true);
    setIsFormSubmitted(true);
    setShowPasswordValidation(true);
  
    // Validate full name
    if (!signupDetails.full_name.trim()) {
      toast.error("Full Name is required.");
      setIsBtnDisabled(false);
      return;
    }
  
    // Validate password length
    if (!passwordValidation.minLength) {
      toast.error("Password must be at least 8 characters long.");
      setIsBtnDisabled(false);
      return;
    }
  
    // Set loading state
    setIsLoading(true);
  
    const payload = {
      email: signupDetails.email,
      password: signupDetails.password,
      full_name: signupDetails.full_name,
    };
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signup/",
        payload
      );
  
      console.log("Signup response:", response.data);
  
      // Check for specific exact message
      if (
        response.data.status === 200 &&
        response.data.message === "A new OTP has been sent to your email. Please verify within 10 minutes."
      ) {
        toast.success("Signup successful! Redirecting to OTP page.", { autoClose: 2000 });
  
        // Save relevant data in localStorage
        localStorage.setItem("email", signupDetails.email);
        localStorage.setItem("otp_verification_required", "true");
  
        // Navigate to OTP page
        navigate("/otp", {
          state: { email: signupDetails.email },
        });
      } 
      // Handle similar messages with dynamic checks
      else if (
        response.data.status === 200 &&
        (
          response.data.message.includes("OTP has been sent") ||
          response.data.message.includes("Check your email for OTP verification")
        )
      ) {
        toast.success("Signup successful! Redirecting to OTP page.", { autoClose: 2000 });
        window.localStorage.setItem("token", response.data.data.token);
        window.localStorage.setItem("email", signupDetails.email);
        window.localStorage.setItem("full_name", response.data.data.full_name); 

        
       
        setToken(response.data.data.token);
        localStorage.setItem("lastname", response.data.data.full_name);

        
        localStorage.setItem("email", signupDetails.email);
        // Save relevant data in localStorage
        localStorage.setItem("email", signupDetails.email);
        localStorage.setItem("otp_verification_required", "true");
  
        // Navigate to OTP page
        navigate("/otp", {
          state: { email: signupDetails.email },
        });
      } else if (response.data.status === 400) {
        setEmailError("Email Already Exists");
        toast.error("Email already exists. Please try logging in.");
        setIsBtnDisabled(false);
      } else {
        toast.error("Unexpected response. Please try again.");
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error during signup:", error);
  
      // Check for a detailed error message from the server
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Signup failed: ${error.response.data.message}`);
      } else {
        toast.error("Signup failed. Please check your internet connection and try again.");
      }
    } finally {
      setIsLoading(false);
      setIsBtnDisabled(false);
    }
  };
  
  
  

  useEffect(() => {
    setIsBtnDisabled(false)
  }, []);



  useEffect(() => {
    
    const chatScript = document.createElement("script");
    chatScript.async = true;
    chatScript.type = "text/javascript";
    chatScript.src = "https://chatling.ai/js/embed.js";
    chatScript.setAttribute("data-id", "2287369244");
    chatScript.id = "chatling-embed-script";

   
    const chatlingConfigScript = document.createElement("script");
    chatlingConfigScript.type = "text/javascript";
    chatlingConfigScript.innerHTML = `
      window.chtlConfig = { 
        chatbotId: "2287369244",
        page: "SignupPage" // Add a specific page identifier
      };
    `;

    document.body.appendChild(chatlingConfigScript);
    document.body.appendChild(chatScript);

    
    return () => {
      document.body.removeChild(chatlingConfigScript);
      document.body.removeChild(chatScript);
    };
  }, []);
  
  return (
  <div className="bg-white ">
   
    <div className=" sigin-container bg-[#FEF9F7]" >
     
      <div className="logo-container">
    <img
      src="/docusensasign.svg"
      alt="docuSensa logo"
      className="w-full h-full object-contain"
    />
  </div>
      

      <section className="flex justify-center items-center">
        <div className="siginCont w-[25.19rem] h-[35rem] border border-[rgba(0,0,0,0.10)] bg-white rounded-[12px] flex flex-col p-6 mt-[115px] ml-[-40px]">
          <div>
            <h2 className="welcome-signin">Get started Now</h2>
            <h3 className="credentials-signin">
            Enter your Credentials to Create an Account
            </h3>
          </div>
          <div>
            <div className="google-signin-button">
            <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage}
      />
             
            </div>

           
          </div>

          <div className="or-style my-6">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-500">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>
          
          <label htmlFor="full_name" className="email-sigin">
  Full Name
</label>
<div className="Email-sigin-box relative flex items-center">
  <img
    src="/fullnameicon.svg"
    alt="FullName icon"
    className="emailsigin-icon w-5 h-5 absolute left-3"
  />
  <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

  <input
    type="text"
    name="full_name"
    value={signupDetails.full_name}  
    onChange={handleSignupDetailsChange}
    className="Email-signup-placeholder border-none outline-none pl-4 ml-14"
    placeholder="Enter your Full Name"
    required
  />


</div>


          <div>
  <label for="email" className="email-sigin mt-3">
    Email
  </label>
  <div className="Email-sigin-box relative flex items-center">
    <img
      src="/email-signin.svg"
      alt="Email icon"
      className="emailsigin-icon w-5 h-5 absolute left-3"
    />
    <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

    <input
      type="email"
      name="email"
      value={signupDetails.email} 
      onChange={handleSignupDetailsChange}
      className="Email-signup-placeholder border-none outline-none pl-4 ml-14" 
      placeholder={emailError || "Enter your Email"}
      required
    />

{emailError && (
  <div
    className="relative flex items-center"
    onMouseEnter={() => setIsHovered(true)}  
    onMouseLeave={() => setIsHovered(false)} 
  >
    <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
      <img src="/error.svg" alt="email error logo" />
    </span>

    
    {isHovered && (
    <div className="popup-show-letter email-error-hover">
        <p>{emailError}</p>
    </div>
)}

  </div>
)}
  </div>
</div>





<div>
  <label htmlFor="password" className="password-sigin">
    Password
  </label>
  <div className="password-sigin-box flex items-center border">
    <img
      src="/passkey.svg"
      alt="Password icon"
      className="emailsigin-icon w-5 h-5 ml-3"
    />
    <div className="line-between-icon ml-3.5 mt-8 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

    <input
      type={showPassword ? "text" : "password"}
      name="password"
      value={signupDetails.password} 
      onChange={handleSignupDetailsChange}
      placeholder={passwordValidation.minLength ? "Enter Your Password" : "Password must be at least 8 characters long"}
      className="password-sigin-placeholder border-none outline-none ml-2"
      required
    />
    {showPasswordValidation && signupDetails.password && !passwordValidation.minLength && (
      <div
        className="relative flex items-center mt-0"
        onMouseEnter={() => setIsHovered(true)}  
        onMouseLeave={() => setIsHovered(false)} 
      >
        <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
          <img src="/error.svg" alt="password error logo" />
        </span>

        
        {isHovered && (
          <div className="popup-show-letter email-error-hover">
            <p>At least 8 Characters</p>
          </div>
        )}
      </div>
    )}

    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="text-gray-500 ml-9"
    >
      {showPassword ? (
        <IoEyeOutline size={22} />
      ) : (
        <IoEyeOffOutline size={22} />
      )}
    </button>
  </div>

  
</div>




<div className="flex items-start">
  <div className="ml-1 text-sm mt-3">
    <label htmlFor="terms" className="policy">
      By clicking create account; I agree to{" "}
      <a 
        href="https://docusensa.com/terms-and-conditions" 
        className="termsandCondition hover:underline" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Terms Of Service
      </a>{" "}
      and{" "}
      <a 
        href="https://docusensa.com/privacy-and-policy" 
        className="termsandCondition hover:underline" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>.
    </label>
  </div>
</div>


             

<div className="send-button-sigin w-[355px] h-[62px] bg-[#8B55DD] rounded-[6px] flex justify-center items-center mt-3">
  <button
    className={`signin-button ${isBtnDisabled ? "notAccepted cursor-not-allowed" : "isTermAccepted"}`}
    onClick={handleSignUp} 
    disabled={isBtnDisabled} 
  >
    {isLoading ? <div className="loading"></div> : "Sign Up"}
  </button>
</div>


        </div>
      </section>

      <p className="login-text-sigin mt-2">
      Have an account??{" "}
      <Link
    to="/signin"
    className="login-click-singin"
    onClick={() => {
      localStorage.removeItem("token");
      localStorage.removeItem("otp_verification_required");
      localStorage.removeItem("email");
      localStorage.removeItem("full_name");
    }}
  >
    Log in
  </Link>
      </p>
      
    </div>
   
    </div>
  );
};

export default SignupPage;
