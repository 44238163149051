import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CreateFolderModal.css";
import { useNavigate } from "react-router-dom";

const CreateFolderModal = ({ isOpen, onClose }) => {
  const [folderName, setFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [createdFolderId, setCreatedFolderId] = useState(null);

  const [messages, setMessages] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");

  const socket = new WebSocket("wss://wa-backend-production-a7adgeg2breuc5dr.centralindia-01.azurewebsites.net/ws/upload-status/");
  socket.onopen = function () {
    console.log("WebSocket connection established.");
  };

  const [currentMessage, setCurrentMessage] = useState("Waiting for updates...");
  const [isUploading, setIsUploading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  socket.onmessage = function (event) {
    const data = JSON.parse(event.data);
    console.log("WebSocket Message:", data);

    if (data.type === "progress" || data.type === "completed") {
      setCurrentMessage(data.message);
      setProgress(data.progress);
    } else if (data.type === "error") {
      console.error("Error:", data.message);
      setCurrentMessage(`Error: ${data.message}`);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    // Cleanup WebSocket connection
    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  if (!isOpen) return null;
  const handleViewFolder = () => {
    onClose();
    navigate('/uploadFile');
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setProgress(0);
  };

  const handleFileUpload = async () => {
    if (!folderName.trim()) {
      alert("Please provide a folder name.");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    setIsCreating(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("folder_name", folderName);
    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/create-folder/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
          onUploadProgress: () => { },
        }
      );

      if (response.status === 200) {
        setIsUploaded(true);
        const { folderId } = response.data;
        setCreatedFolderId(folderId);
      } else {
        alert(response.data.msg || "Folder creation failed");
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert(error.response?.data.msg || "An error occurred. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };


  const handleClose = () => {
    setFolderName("");
    setFile(null);
    setProgress(0);
    setIsCreating(false);
    setIsUploaded(false);
    setCreatedFolderId(null);
    onClose();
  };



  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={handleClose}>
          x
        </button>
        {!isCreating && !isUploaded ? (
          <>
            <h2 className="modal-title">Create a new folder</h2>
            <p className="modal-description">
              Name your folder and Click to upload your files  to organize them right
              away
            </p>

            <div className="input-group">
              <label htmlFor="folderName">Title</label>
              <input
                type="text"
                id="folderName"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder="Enter here"
              />
            </div>

            <div
              className={`upload-area ${isDragOver ? "drag-over" : ""}`}
              onClick={() => document.getElementById("fileInput").click()}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragOver(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragOver(false);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragOver(false);
                const droppedFile = e.dataTransfer.files[0];
                if (droppedFile) {
                  handleFileChange({ target: { files: [droppedFile] } });
                }
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="file-placeholder">
                <div className="file-icon-folder">
                  <img src="/path-to-file-icon.svg" alt="File Icon" />
                  <img src="/uploadSmall.svg" alt="Overlay Icon" className="upload-small-folder" />
                </div>
                <p className="choose-file">
                  Click to upload your files or{" "}
                  <span className="choose-file-span">Choose File</span>
                </p>
                {file && (
                  <p className="selected-file">
                    {/* Optionally show file name */}
                  </p>
                )}
              </div>
            </div>



            <button className="modal-button" onClick={handleFileUpload}>
              Create Folder
            </button>
          </>
        ) : isCreating ? (
          <div>
            <div className="title-name">
              <h2 className="modal-title">Create a new folder</h2>
            </div>
            <p className="modal-description">
              Name your folder and drag & drop documents to organize them right
              away
            </p>

            <div className="input-group">
              <label htmlFor="folderName">Title</label>
              <input
                type="text"
                id="folderName"
                value={folderName}
                placeholder="Enter here"
                disabled
              />
            </div>

            <div className="progress-area">
              <img
                src="/uploadingFolderIcon.svg"
                alt="File Icon"
                className="Uploading-icon-doc"
              />
              <p className="doc-uploading-para-one">{currentMessage}</p>
              <p className="doc-uploading-para-two">Your Progress</p>
              <div className="progress-container">
                <p className="progress-percentage">{progress}% to complete</p>

                <p className="doc-time-estimate">⏱1min</p>
              </div>

              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="uploaded-Success-Area-Doc">
              <div className="success-icon-upload">
                <img src="/right-success.svg" alt="Success Icon" className="checkmark-icon" />

              </div>

              <h2 className="modal-title-doc-success">
                Folder created successfully
              </h2>

              <p className="modal-description-doc-success">
                {file
                  ? "Your file has been uploaded successfully into your folder"
                  : ""}
              </p>
              {file && (
                <div className="success-details">
                  <img src="/uploaded-doc-icon-file.svg" alt="File Icon" />
                  <p>{file?.name || "Uploaded_File.pdf"}</p>
                </div>
              )}
            </div>

            <button
              className="modal-button-final-success"
              onClick={() => {
                handleClose();
                navigate(`/uploadFile`, {
                });
              }}
            >
              View Folder
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateFolderModal;
