import React, { useState, useEffect, useRef } from "react";
import { Header } from "../../components/Header/Header";
import "./accountPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AccountPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const storedFullName = localStorage.getItem("full_name") || "John Doe";
    const storedEmail = localStorage.getItem("email") || "example@gmail.com";

    setFullName(storedFullName);
    setEmail(storedEmail);
  }, []);

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    localStorage.setItem("fullName", e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    localStorage.setItem("email", e.target.value);
  };
  const handleUpload = () => {
    alert("Upload option selected");
    setIsPopupVisible(false); 
  };

  const handleDelete = () => {
    alert("Delete option selected");
    setIsPopupVisible(false);
  };
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false); 
      }
    };

    
    document.addEventListener("mousedown", handleClickOutside);

   
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const updateFullName = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("User is not authenticated. Please log in.");
      return;
    }
  
    if (!fullName.trim()) {
      alert("Full Name cannot be empty.");
      return;
    }
  
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/update-full-name/`,
        { full_name: fullName }, // Send only full_name
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        alert(response.data.message); // Show success message
        
        // Ensure only "full_name" is stored and used
        localStorage.setItem("full_name", response.data.full_name); // Store correct key
        setFullName(response.data.full_name); // Update state
        setIsEditing(false); // Exit edit mode
      } else {
        alert("Failed to update name.");
      }
    } catch (error) {
      console.error("Error updating name:", error.response || error);
      alert("An error occurred while updating the name. Please try again.");
    }
  };
  
  
  

  const handleResetPassword = () => {
    navigate("/request-password-reset"); 
  };


const handleDeleteAccount = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    alert("User is not authenticated. Please log in.");
    return;
  }

  const confirmed = window.confirm(
    "Are you sure you want to delete your account? This action cannot be undone."
  );
  if (!confirmed) return;

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}api/v1/accounts/delete-account/`,
      {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Check if response status is 200
    if (response.status === 200) {
      alert(response.data.message); // Show success message
      localStorage.clear(); // Clear user data from localStorage
      navigate("/signin"); // Redirect to sign-in page
    } else {
      alert("Failed to delete account. Please try again.");
    }
  } catch (error) {
    console.error("Error deleting account:", error);
    alert("An error occurred while trying to delete your account.");
  }
};

const handleLogout = () => {
    localStorage.removeItem("full_name");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin"); 
    window.location.reload(); 
  };
  

  return (
    <div>
      <div className="">
        <Header name="Account" icon={""} />
      </div>
      <div className="account-box-area-profile">
        <h2 className="personal-info-account">Personal Information’s</h2>
        <div className="flex gap-8">
          <div className="circle-account-profile cursor-pointer">
            <span className="account-name-profile">
              {fullName ? fullName[0] : "U"}
            </span>

            {/* <div className="edit-icon-account" onClick={togglePopup}>
  <img src="/edit-icon-account.svg" alt="Edit Icon" />
</div> */}


            {isPopupVisible && (
              <div className="popup-container">
                <div className="popup-option" onClick={handleUpload}>
                  <img
                    src="/upload-icon.svg"
                    alt="Upload Icon"
                    className="popup-icon"
                  />
                  <span>Upload</span>
                </div>
                <div className="popup-option" onClick={handleDelete}>
                  <img
                    src="/delete.svg"
                    alt="Delete Icon"
                    className="popup-icon"
                  />
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex">
        <div className="flex flex-col">
            <label htmlFor="fullName" className="Full-Name-Account">
              Full Name
            </label>
            <div className="relative">
              <input
                id="fullName"
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                className="namehere-account h-12 px-5 py-3 rounded-lg border border-gray-200 bg-white font-outfit text-[16px] font-bold text-gray-600"
                disabled={!isEditing}
              />
               <img
                src={isEditing ? "/edit-icon-account.svg" : "/edit-icon-account.svg"}
                alt="Edit"
                className="absolute right-3 top-6 w-4 h-4 cursor-pointer"
                onClick={() => (isEditing ? updateFullName() : setIsEditing(true))}
              />
            </div>
          </div>

          <div className="flex flex-col email-container">
            <label htmlFor="email" className="Full-Name-Account">
              Email ID
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="namehere-account"
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div>
          <h2 className="personal-info-account-password">Password</h2>

          <p className="personal-info-account-secure">Secure Your Account</p>
        </div>
        <button className="account-button-profile-reset" onClick={handleResetPassword}>Reset password</button>
      </div>

      <div className="flex">
        <div>
          <h2 className="personal-info-account-password">
            Delete Your Account
          </h2>
          <p className="personal-info-account-secure">
            Permanently delete your account
          </p>
        </div>
        <button className="account-button-profile-delete-account" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>
      <div>
        <p className="logout-account-btton cursor-pointer" onClick={handleLogout}>Logout</p>
      </div>
    </div>
  );
};

export default AccountPage;
